import * as React from "react";
import { getStorage } from "firebase/storage";
import { StorageFileData, getFolderFiles } from "../../../lib/ImageHandling";
import MediaContainer from "../../home/components/imageList/MediaContainer";
import ImageContainer from "../../home/components/imageList/ImageContainer";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

// Create a reference with an initial file path and name

interface IImageListProps {
  folderPath: string;
  displayFullImage?: boolean;
}

const ImageList: React.FunctionComponent<IImageListProps> = ({
  folderPath,
  displayFullImage,
}) => {
  const gotData = React.useRef<boolean>(false);
  const [images, setImages] = React.useState<StorageFileData[]>([]);
  const storage = getStorage();
  React.useEffect(() => {
    if (gotData.current) return;
    gotData.current = true;
    getFolderFiles(storage, folderPath).then((res) => {
      setImages(res);
    });
  });

  return (
    <Grid container spacing={1} sx={{ flexGrow: 1, justifyContent: "center" }}>
      {images.map((img, i) => {
        if (
          img.name.toLocaleLowerCase().split(".")[1] !== "png" &&
          img.name.toLocaleLowerCase().split(".")[1] !== "jpg" &&
          img.name.toLocaleLowerCase().split(".")[1] !== "jpeg"
        ) {
          return (
            <Grid xs="auto" key={`${img.url}-${i}`}>
              <MediaContainer image={img} height={"225"} />
            </Grid>
          );
        }
        return (
          <Grid xs="auto" key={`${img.url}-${i}`}>
            <ImageContainer
              image={img}
              height={"30vh"}
              displayFullImage={displayFullImage}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ImageList;
