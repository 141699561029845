import { Box } from "@mui/material";
import * as React from "react";
import CategoriesList from "./components/CategoriesList";
import paintings from "../../content/paintings";

interface IPaintingsProps {}

const Paintings: React.FunctionComponent<IPaintingsProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10vh"
        // height: "75vh",
      }}
    >
      <CategoriesList cards={paintings.series} />
    </Box>
  );
};

export default Paintings;
