import * as React from "react";
import { Box, Typography } from "@mui/material";
import leventeProfil from "../../../images/LeventeProfil.jpeg";
import backgroundImage from "../../../images/Banner_BG.JPG";
import { isMobile } from "react-device-detect";

interface IBannerProps {}

const Banner: React.FunctionComponent<IBannerProps> = (props) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        marginTop: "60px",
        marginBottom: "120px",
        "::before": {
          background: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          opacity: "0.4",
          content: "''",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          zIndex: "-1",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-end",
          textAlign: "right",
          marginRight: "30px",
        }}
      >
        <Typography
          variant="h1"
          sx={{ fontSize: isMobile ? "24px" : "72px", fontWeight: "bold" }}
        >
          HORVÁTH
        </Typography>
        <Typography
          variant="h1"
          sx={{ fontSize: isMobile ? "24px" : "72px", fontWeight: "bold" }}
        >
          LEVENTE
        </Typography>
      </Box>
      <Box
        sx={{
          width: isMobile ? "200px" : "400px",
          height: isMobile ? "300px" : "500px",
          background: `url(${leventeProfil})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "6px",
          boxShadow: "2px 8px 16px 0px rgba(0,0,0,0.18)",
        }}
      ></Box>
    </Box>
  );
};

export default Banner;
