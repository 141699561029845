import { Box, List, ListItem, ListItemButton, Typography } from "@mui/material";
import * as React from "react";
import { StorageFileData, getFolderFiles } from "../lib/ImageHandling";
import { getStorage } from "firebase/storage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IPressPageProps {}

const PressPage: React.FunctionComponent<IPressPageProps> = (props) => {
  const gotData = React.useRef<boolean>(false);
  const [files, setFiles] = React.useState<StorageFileData[]>([]);
  const storage = getStorage();
  const { t } = useTranslation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (gotData.current) return;
    gotData.current = true;
    getFolderFiles(storage, "press").then((res) => {
      setFiles(res);
    });
  });
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        width: "70%",
        marginTop: "15vh",
        flexDirection: "column",
        marginLeft: "10vw",
      }}
    >
      <Typography
        variant="h2"
        sx={{ marginBottom: "60px", marginLeft: "30px" }}
      >
        {t("headerPress")}
      </Typography>
      <List sx={{ maxWidth: "700px" }}>
        <ListItem key={"openings"}>
          <ListItemButton
            onClick={() => {
              navigate("megnyitok");
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography>{t("megnyitok")}</Typography>
            </Box>
          </ListItemButton>
        </ListItem>
        {files.map((file) => {
          return (
            <ListItem key={file.url}>
              <ListItemButton
                onClick={() => {
                  window.open(file.url, "_blank");
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {file.name.split(".")[0]?.split("_")?.length === 2 ? (
                    <>
                      <Typography>
                        {file.name.split(".")[0]?.split("_")[1]}
                      </Typography>
                      <Typography fontSize={14}>
                        {file.name.split(".")[0]?.split("_")[0]}
                      </Typography>
                    </>
                  ) : (
                    <Typography>
                      {file.name.split(".")[0]?.split("_")[0]}
                    </Typography>
                  )}
                </Box>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default PressPage;
