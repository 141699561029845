import { Fab } from "@mui/material";
import * as React from "react";

interface ICallToActionProps {
  label: string;
  onClick: () => void;
  color?: "primary" | "secondary";
}

const CallToAction: React.FunctionComponent<ICallToActionProps> = ({
  label,
  onClick,
  color = "primary",
}) => {

  return (
    <Fab
      onClick={onClick}
      color={color}
      variant="extended"
      sx={{
        borderRadius: "0px",
        backgroundColor: "#FAFAFA",
        color: "black",
        border: "1px solid black",
        // boxShadow: "none",
        ":hover": {
          backgroundColor: "#fafafa",
        },
      }}
    >
      {label}
    </Fab>
  );
};

export default CallToAction;
