import * as React from "react";
import { StorageFileData } from "../../../../lib/ImageHandling";
import { Box } from "@mui/material";

interface IMediaContainerProps {
  image: StorageFileData;
  height: string | number;
}

const MediaContainer: React.FunctionComponent<IMediaContainerProps> = ({
  image,
  height,
}) => {
  return (
    <Box
      sx={{
        marginRight: "10px",
        marginLeft: "10px",
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <iframe
        height={height}
        width={parseInt(height as string) * 1.75}
        src={`https://www.youtube.com/embed/${
          image.name.split("?")[1]?.split(".")[0]
        }`}
        title="Before"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
    </Box>
  );
};

export default MediaContainer;
