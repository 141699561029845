import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#517664",
    },
  },
  typography: {
    h1: {
      fontFamily: "Montserrat, sans-serif",
      fontOpticalSizing: "auto",
      fontStyle: "normal",
    },
    h2: {
      fontFamily: "Montserrat, sans-serif",
      fontOpticalSizing: "auto",
      fontStyle: "normal",
    },
    h3: {
      fontFamily: "Montserrat, sans-serif",
      fontOpticalSizing: "auto",
      fontStyle: "normal",
    },
    h4: {
      fontFamily: "Montserrat, sans-serif",
      fontOpticalSizing: "auto",
      fontStyle: "normal",
    },
    h5: {
      fontFamily: "Montserrat, sans-serif",
      fontOpticalSizing: "auto",
      fontStyle: "normal",
    },
  },
});

export default theme;
