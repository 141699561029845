import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ISeries } from "../../../content/base";

interface ICategoryCardProps {
  card: ISeries;
}

const CategoryCard: React.FunctionComponent<ICategoryCardProps> = ({
  card,
}) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        width: "300px",
        height: "450px",
        marginRight: "20px",
        marginBottom: "20px",
      }}
    >
      <CardActionArea
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
        onClick={() => {
          navigate(card.path);
        }}
      >
        <CardContent sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h4"
            fontSize={28}
            fontWeight={500}
            sx={{ marginBottom: "60px" }}
          >
            {card.title}
          </Typography>
          <Typography textAlign={"justify"}>{card.description}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CategoryCard;
