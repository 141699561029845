import { Box, Typography } from "@mui/material";
import * as React from "react";

interface IPageTitleProps {
  title: string;
}

const PageTitle: React.FunctionComponent<IPageTitleProps> = ({ title }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "40px",
        marginBottom: "60px",
      }}
    >
      <Typography
        variant="h1"
        fontSize={52}
        fontWeight={500}
        textTransform={"uppercase"}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default PageTitle;
