import {
  AppBar,
  Box,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

interface IAppHeaderProps {}

const AppHeader: React.FunctionComponent<IAppHeaderProps> = (props) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0} color="transparent">
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {!isMobile && (
              <Box
                sx={{ width: "250px" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: "24px",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  Horváth Levente
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginRight: isMobile ? "10px" : "20px",
                  fontSize: isMobile ? "0.8em" : "1.071em",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/festmenyek");
                }}
              >
                {t("headerPainting")}
              </Typography>
              {!isMobile && (
                <Typography
                  variant="h5"
                  sx={{
                    marginRight: isMobile ? "10px" : "20px",
                    fontSize: isMobile ? "0.8em" : "1.071em",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/fotok-videok");
                  }}
                >
                  {t("headerMedia")}
                </Typography>
              )}
              {!isMobile && (
                <Typography
                  variant="h5"
                  sx={{
                    marginRight: isMobile ? "10px" : "20px",
                    fontSize: isMobile ? "0.8em" : "1.071em",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate("/restauralas");
                  }}
                >
                  {t("headerRestoration")}
                </Typography>
              )}
              <Typography
                variant="h5"
                sx={{
                  marginRight: isMobile ? "10px" : "20px",
                  fontSize: isMobile ? "0.8em" : "1.071em",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/egyebek");
                }}
              >
                {t("headerOther")}
              </Typography>

              <Typography
                variant="h5"
                sx={{
                  marginRight: isMobile ? "10px" : "20px",
                  fontSize: isMobile ? "0.8em" : "1.071em",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/oneletrajz");
                }}
              >
                C.V.
              </Typography>

              <Typography
                variant="h5"
                sx={{
                  marginRight: isMobile ? "10px" : "20px",
                  fontSize: isMobile ? "0.8em" : "1.071em",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/sajto");
                }}
              >
                {t("headerPress")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: isMobile ? "80px" : "250px",
              }}
            >
              <Select
                labelId="select-language"
                id="language-select"
                variant={"standard"}
                value={i18n.language}
                slotProps={{
                  input: {
                    sx: {
                      "::backdrop": "none !importan",
                    },
                  },
                  root: {
                    sx: {
                      "::backdrop": "none !importan",
                    },
                  },
                }}
                sx={{
                  width: "50px",
                  "::backdrop": {
                    backgroundColor: "transparent !important",
                  },
                }}
                label="Language"
                onChange={(e) => {
                  i18n.changeLanguage(e.target.value);
                }}
              >
                <MenuItem value={"en"}>en</MenuItem>
                <MenuItem value={"hu"}>hu</MenuItem>
              </Select>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default AppHeader;
