import { Box, Typography } from "@mui/material";
import * as React from "react";
import ImageList from "../imageList/ImageList";
import CallToAction from "../../../../inputs/CallToAction";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

interface CTAProps {
  label: string;
  url: string;
}

interface ICategoryContainerProps {
  folderPath: string;
  title: string;
  align?: "left" | "right";
  cta: CTAProps;
}

const CategoryContainer: React.FunctionComponent<ICategoryContainerProps> = ({
  title,
  folderPath,
  align = "left",
  cta,
}) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "5vw",
        marginRight: "5vw",
        marginBottom: "240px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: align === "right" ? "flex-end" : "flex-start",
        }}
      >
        <Typography
          variant="h2"
          fontSize={isMobile ? 24 : undefined}
          fontWeight={400}
          sx={{ marginBottom: "50px" }}
        >
          {title}
        </Typography>
        <ImageList folderPath={folderPath} />
        <Box
          sx={{
            marginTop: "50px",
            // width: "100%",
            display: "flex",
            justifyContent: align === "right" ? "flex-end" : "flex-start",
          }}
        >
          <CallToAction
            label={cta.label}
            onClick={() => {
              navigate(cta.url);
            }}
          />
        </Box>
      </Box>
    </Box>
    // </Box>
  );
};

export default CategoryContainer;
