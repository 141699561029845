import { Box } from "@mui/material";
import * as React from "react";
import ImageList from "./components/ImageList";

interface ISeriesPageProps {
  storagePath: string;
}

const SeriesPage: React.FunctionComponent<ISeriesPageProps> = ({
  storagePath,
}) => {
  // const fetchedMedia = React.useRef<boolean>(false);
  // const [media, setMedia] = React.useState<StorageFileData[]>([]);
  // const storage = getStorage();

  // React.useEffect(() => {
  //   if (fetchedMedia.current) return;
  //   fetchedMedia.current = true;
  //   getFolderFiles(storage, storagePath).then((res) => {
  //     setMedia(res);
  //   });
  // });
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        marginTop: "10vh",
      }}
    >
      <ImageList folderPath={storagePath} displayFullImage/>
    </Box>
  );
};

export default SeriesPage;
