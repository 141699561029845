import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import PublicLayout from "./layouts/public/PublicLayout";
import Autobiography from "./pages/categories/Autobiography";
import Paintings from "./pages/categories/Paintings";
import SeriesPage from "./pages/series/SeriesPage";
import PressPage from "./pages/Press";

function App() {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route index element={<Home />} />
        <Route path="festmenyek" element={<Paintings />} />

        <Route
          path="festmenyek/csur"
          element={
            <SeriesPage storagePath="paintings/csur" key={"paintings/csur"} />
          }
        />
        <Route
          path="festmenyek/fa"
          element={
            <SeriesPage storagePath="paintings/fa" key={"paintings/fa"} />
          }
        />
        <Route
          path="festmenyek/taj"
          element={
            <SeriesPage storagePath="paintings/taj" key={"paintings/taj"} />
          }
        />
        <Route
          path="fotok-videok"
          element={<SeriesPage storagePath="media" key={"media"} />}
        />
        <Route path="oneletrajz" element={<Autobiography />} />
        <Route
          path="restauralas"
          element={<SeriesPage storagePath="restoration" key={"restoration"} />}
        />
        <Route
          path="egyebek"
          element={<SeriesPage storagePath="other" key={"other"} />}
        />
        <Route path="sajto" element={<PressPage />} />
        <Route
          path="sajto/megnyitok"
          element={<SeriesPage storagePath="opening" key={"opening"} />}
        />
      </Route>
    </Routes>
  );
}

export default App;
