import IBaseContent from "./base";

const paintings: IBaseContent = {
  title: "Festmények",
  translationId: "festmenyek",
  series: [
    {
      title: "Csűr",
      translationId: "csur",
      path: "/festmenyek/csur",
      description:
        "Az erdélyi falvak régi gazdasági épületei (a falusi földműves ember csűrje) tájrendező elemek uralják a teret. \nNincs két egyforma csűrépület, nüanszaiban a gazda leleményességéhez , igényeihez, belső világához igazodik. Ami kint van, követi azt, ami bent van. ",
    },
    {
      title: "Táj",
      translationId: "taj",
      path: "/festmenyek/taj",
      description:
        "„Tájra emlékeztető képtest imaginárius erővel. Külső táj nincs, a magunkban hordozott világot vetítjük ki magunkból. A kép médium egyfajta ikon, csatorna befelé, csak a bennünk lakozót ébresztheti fel.”",
    },
    {
      title: "Fa",
      translationId: "fa",
      path: "/festmenyek/fa",
      description:
        "Fatörténetek vannak feldolgozva. \nFenyvesek, tarvágás, tisztás. Kitermelt farönkök őrzői, \nnem tudom, hogy kik is ők, a volt erdő volt emberei, favágók, fahúzatók, éjjeli\nőrként saját áldozatukat őrzők.",
    },
  ],
};

export default paintings;
