import * as React from "react";
import Banner from "./components/Banner";
import CategoryContainer from "./components/categoryLists/CategoryContainer";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
interface IHomeProps {}

const Home: React.FunctionComponent<IHomeProps> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Banner />
      <CategoryContainer
        title={t("paintingsTitle")}
        folderPath="home/paintings"
        cta={{
          label: t("paintingsCTA"),
          url: "/festmenyek",
        }}
      />
      <CategoryContainer
        title={t("mediaTitle")}
        folderPath="home/media"
        align="right"
        cta={{
          label: t("mediaCTA"),
          url: "/fotok-videok",
        }}
      />
      <CategoryContainer
        title={t("restorationTitle")}
        folderPath="home/restoration"
        cta={{
          label: t("restorationCTA"),
          url: "/restauralas",
        }}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography fontSize={12}>
          © Portrét készítette: Szentes Zágon
        </Typography>
      </Box>
    </>
  );
};

export default Home;
