import { Box } from "@mui/material";
import * as React from "react";
import CategoryCard from "./CategoryCard";
import { ISeries } from "../../../content/base";
import { isMobile } from "react-device-detect";

interface ICategoriesListProps {
  cards: ISeries[];
}

const CategoriesList: React.FunctionComponent<ICategoriesListProps> = ({
  cards,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "20px",
        // maxHeight: "100vh",
        overflow: "auto",
      }}
    >
      {cards.map((c) => (
        <CategoryCard card={c} key={c.title} />
      ))}
    </Box>
  );
};

export default CategoriesList;
