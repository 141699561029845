import { Box, CircularProgress } from "@mui/material";
import * as React from "react";
import PageTitle from "./components/PageTitle";
import { useTranslation } from "react-i18next";
import { getStorage } from "firebase/storage";
import { StorageFileData, getFolderFiles } from "../../lib/ImageHandling";

interface IAutobiographyProps {}

const Autobiography: React.FunctionComponent<IAutobiographyProps> = (props) => {
  const { t } = useTranslation();
  const storage = getStorage();
  const [cv, setCv] = React.useState<StorageFileData>();
  const gotData = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (gotData.current) return;
    gotData.current = true;
    getFolderFiles(storage, "cv").then((res) => {
      setCv(res[0]);
    });
  });

  return (
    <Box>
      <PageTitle title={t("autobiographyTitle")} />
      <Box
        sx={{
          minWidth: "100%",
          minHeight: "100%",
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          marginBottom: "10vh",
        }}
      >
        {cv ? (
          <iframe src={cv.url} width={"70%"} height={"900"} title="cv" />
        ) : (
          <CircularProgress />
        )}
        {/* <embed src={oneletrajz} width={"70%"} height={"900"} type=""/> */}
      </Box>
    </Box>
  );
};

export default Autobiography;
