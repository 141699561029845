import { ref, listAll, FirebaseStorage } from "firebase/storage";

export interface StorageFileData {
  name: string;
  url: string;
}

const getFolderFiles = async (
  storage: FirebaseStorage,
  path: string
): Promise<StorageFileData[]> => {
  const folderRef = ref(storage, path);
  const files: StorageFileData[] = [];
  const all = await listAll(folderRef);
  all.items.forEach((imageRef) => {
    files.push({
      name: imageRef.name,
      url: `https://firebasestorage.googleapis.com/v0/b/${
        imageRef.bucket
      }/o/${encodeURIComponent(imageRef.fullPath)}?alt=media`,
    });
  });

  return files.sort((a, b) => -a.name.localeCompare(b.name));
};

export { getFolderFiles };
