import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// @TODO https://stackoverflow.com/questions/1828317/internationalization-and-search-engine-optimization
// @TODO http://www.ninebyblue.com/blog/making-geotargeted-content-findable-for-the-right-searchers/

/**
 *
 * How to approach internationalisation
 *
 * Probably the best way would be to create a root path for these languages
 *
 * So for the english version would be localhost/en/....
 * For the hungarian version would be localhost/hu/.....
 * And for the romanian version would be localhost/ro/..
 *
 */

if (localStorage.getItem("i18nextLng") === null) {
  localStorage.setItem("i18nextLng", "hu");
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "hu",
    resources: {
      hu: {
        translation: {
          /* Landing page */
          paintingsTitle: "Festmények",
          paintingsCTA: "TELJES PROTFÓLIÓ MEGTEKINTÉSE",

          mediaTitle: "Fotók és Videók",
          mediaCTA: "FOTÓK ÉS VIDEÓK GYÜJTEMÉNYE",

          restorationTitle: "Restaurálás",
          restorationCTA: "ÖSSZES RESTAURÁLÁS",

          /* AppBar  */
          headerPainting: "Festmények",
          headerMedia: "Fotók-Videók",
          headerRestoration: "Restaurálás",
          headerOther: "Más",
          headerPress: "Sajtó",

          /* C.V. */
          autobiographyTitle: "Szakmai önéletrajz",

          megnyitok: "Megnyitók",
        },
      },
      en: {
        translation: {
          /* Landing page */
          paintingsTitle: "Paintings",
          paintingsCTA: "SEE FULL PORTFOLIO",

          mediaTitle: "Photos and Videos",
          mediaCTA: "VISIT COLLECTION",

          restorationTitle: "Restoration",
          restorationCTA: "ALL RESTORATION WORKS",

          /* AppBar  */
          headerPainting: "Paintings",
          headerMedia: "Photos-Videos",
          headerRestoration: "Restoration",
          headerOther: "Other",
          headerPress: "Press",

          /* C.V. */
          autobiographyTitle: "C V",

          megnyitok: "Gallery Openings",
        },
      },
    },
  });
