import * as React from "react";
import { Outlet } from "react-router-dom";
import AppHeader from "./components/Header";

interface IPublicLayoutProps {}

const PublicLayout: React.FunctionComponent<IPublicLayoutProps> = (props) => {
  return (
    <>
      <AppHeader />
      <Outlet />
    </>
  );
};

export default PublicLayout;
