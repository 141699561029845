import { Box, Modal, Typography } from "@mui/material";
import * as React from "react";
import { StorageFileData } from "../../../../lib/ImageHandling";
import { isMobile } from "react-device-detect";

interface IImageContainerProps {
  image: StorageFileData;
  height: string | number;
  displayFullImage?: boolean;
}

const ImageContainer: React.FunctionComponent<IImageContainerProps> = ({
  image,
  height,
  displayFullImage = false,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  return (
    <Box
      sx={{
        marginRight: "10px",
        marginLeft: "10px",
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
        minWidth: (window.innerWidth * 0.9 - 100) / 6,
      }}
    >
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        style={{ backgroundColor: "#FAFAFA" }}
      >
        <Box
          onClick={() => {
            setIsModalOpen(false);
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <img
            src={image.url}
            alt={image.name}
            // loading="lazy"
            style={{
              maxHeight: "95vh",
              maxWidth: "95vw",
              objectFit: "cover",
            }}
          />
        </Box>
      </Modal>
      <img
        onClick={() => {
          setIsModalOpen(true);
        }}
        src={image.url}
        alt={image.name}
        // loading="lazy"
        style={{
          height: height,
          cursor: "pointer",
          maxWidth: displayFullImage
            ? undefined
            : isMobile
            ? "85vw"
            : (window.innerWidth * 0.9 - 120) / 4,
          objectFit: "cover",
        }}
      />
      <Typography sx={{ marginTop: "10px" }}>
        {image.name.split("_")[1]?.split(".")[0]}
      </Typography>
      <Typography sx={{ marginTop: "0px" }}>
        {image.name.split("_")[2]?.split(".")[0]}
      </Typography>
      <Typography sx={{ marginTop: "0px" }} fontSize={12}>
        {image.name.split("_")[0]}
      </Typography>
    </Box>
  );
};

export default ImageContainer;
