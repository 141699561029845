import { Box } from "@mui/material";
import * as React from "react";
import { StorageFileData, getFolderFiles } from "../../../../lib/ImageHandling";
import { getStorage } from "firebase/storage";
import ImageContainer from "./ImageContainer";
import MediaContainer from "./MediaContainer";

// Create a reference with an initial file path and name

interface IImageListProps {
  folderPath: string;
}

const ImageList: React.FunctionComponent<IImageListProps> = ({
  folderPath,
}) => {
  const gotData = React.useRef<boolean>(false);
  const [images, setImages] = React.useState<StorageFileData[]>([]);
  const storage = getStorage();
  React.useEffect(() => {
    if (gotData.current) return;
    gotData.current = true;
    getFolderFiles(storage, folderPath).then((res) => {
      setImages(res);
    });
  });

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {images.map((img, i) => {
        if (
          img.name.toLocaleLowerCase().split(".")[1] !== "png" &&
          img.name.toLocaleLowerCase().split(".")[1] !== "jpg" &&
          img.name.toLocaleLowerCase().split(".")[1] !== "jpeg"
        ) {
          return (
            <MediaContainer
              key={`${img.url}-${i}`}
              image={img}
              height={"225"}
            />
          );
        }
        return (
          <ImageContainer key={`${img.url}-${i}`} image={img} height={"40vh"} />
        );
      })}
    </Box>
  );
};

export default ImageList;
